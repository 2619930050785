@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.App {
    background-color: #FAFAFB !important;
    height: 100vh;
}

* {
    font-family: 'Poppins', sans-serif;
}

.card-drag {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 50px 30px;
    min-width: 320px;
    max-width: 401px;
}

.card-drag span {
    width: 100%;
}

.card-drag h4 {
    font-weight: 500;
    font-size: 18px;
    color: #4F4F4F;
    margin-bottom: 20px;
}

.card-drag h3 {
    font-weight: 500;
    font-size: 18px;
    color: #4F4F4F;

}

.card-drag .specs {
    font-weight: 500;
    font-size: 10px;
    color: #828282;
}

.card-drag .or {
    font-weight: 500;
    font-size: 12px;
    color: #BDBDBD;
}

button {
    background: #2F80ED;
    border-radius: 8px !important;
    color: white;
    border: 1px solid #2F80ED;
    font-size: 12px !important;
    padding: 0.5rem 1rem;
}

.card-drag .drop {
    background: #F6F8FB;
    border: 2px dashed #97BEF4;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px !important;
    color: #BDBDBD;
    padding: 2rem 5rem;
    margin: 1rem 0;
    position: relative;
}

.card-drag .drop p {
    margin-top: 30px;
}

.card-drag .check {
    margin-bottom: 10px;
}

.card-drag .image-uploaded {
    border-radius: 12px;
    max-height: 230px;
    object-fit: cover;
    object-position: top;
    margin-top: 25px;
}

.card-drag .box p {
    font-size: 12px;
    max-width: 240px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}

input {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

input:focus {
    outline: none;
}

/* section {
    position: relative;
    margin: 25px 0 15px;
    border: 2px dotted lightgray;
    padding: 35px 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

input {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

input:focus {
    outline: none;
}

label {
    top: -21px;
    font-size: 13px;
    color: black;
    left: 0;
    position: absolute;
}

p {
    font-weight: bold;
    letter-spacing: 2.2px;
    margin-top: 0;
    text-align: center;
}

button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #3498db;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 1.1em 2.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    color: #3498db;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 250ms ease-in-out;
    font-family: "Open Sans", sans-serif;
    width: 45%;
    display: flex;
    align-items: center;
    padding-right: 0;
    justify-content: center;
}

button::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: width 250ms ease-in-out;
}

i {
    font-size: 22px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

i:hover {
    color: #fff;
    outline: 0;
    background: transparent;
}

i:after {
    width: 110%;
}

i:focus {
    outline: 0;
    background: transparent;
}

i:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
}

article {
    margin-bottom: 35px;
}

article span {
    font-size: 14px;
}

section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
} */